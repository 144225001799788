import { useEffect, useState } from "react"
import { getAuth } from "firebase/auth"
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth"
import axios from "axios"
import localforage from "localforage"
import { Inspector } from "./Inspection"
import { doc, getFirestore, setDoc } from "firebase/firestore"

type LoginType = {
  email: string
  password: string
}
export const login = (data: LoginType): Promise<Inspector | null> => {
  return new Promise(async (resolve, reject) => {
    const hdmk_config = {
      method: "post",
      url: `${process.env.GATSBY_API_ENDPOINT}/auth`,
      data,
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
    }

    try {
      const response = await axios(hdmk_config)

      if (response.status !== 200) return reject("login failed")

      const inspector = response.data as Inspector
      const docPath = `inspectors/${inspector.uid}`
      const document = doc(getFirestore(), docPath)
      await signInWithCustomToken(getAuth(), inspector.token)
      // await signInWithPopup(auth, provider)
      inspector.uid = inspector.uid.toString()
      await localforage.setItem("user", inspector)
      setDoc(document, inspector, { merge: true })

      resolve(inspector)
    } catch (error) {
      reject(error)
    }
  })
}

export const onAuthState = (cb: (x: Inspector | null) => void) => {
  return onAuthStateChanged(getAuth(), async user => {
    if (!user) return cb(null)
    const inspector = (await localforage.getItem("user")) as Inspector
    cb(inspector)
  })
}

export const useAuth = (fn?: () => void) => {
  const [authenticated, setAuthenticated] = useState<Inspector | null>(null)
  useEffect(() => {
    function onAuthStateChanged(user: Inspector | null) {
      if (fn) fn()
      if (authenticated && user) return
      setAuthenticated(user)
    }
    const unsubscribe = onAuthState(onAuthStateChanged)
    return () => unsubscribe()
  })
  return authenticated
}
