import React from "react"
import type { GatsbyBrowser } from "gatsby"
import { initializeApp } from "firebase/app"
import { initializeFirestore, persistentLocalCache } from "firebase/firestore"
import { Provider } from "./src/services/Provider"
import "./src/global.scss"

const app = initializeApp({
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
})
initializeFirestore(app, { localCache: persistentLocalCache() })

// export const wrapRootElement = Provider

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = options => {
  const { element } = options
  //@ts-ignore
  return <Provider>{element}</Provider>
}
export const onServiceWorkerUpdateReady = async () => {
  try {
    const message = `This application has been updated. In order for the updates to take effect you have to reload this app`
    window.alert(message)
  } catch (e) {
    console.log(e)
    window.location.reload()
  }
}
